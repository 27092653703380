import React from "react";
import {graphql} from "gatsby";
import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import ServiceCard from '../components/serviceCard'
import Seo from "../components/seo";

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 4em 1em;
    position: relative;
    overflow: hidden;
    
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
`

const Services = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a{
    &:hover{
      text-decoration: none;
    }
  }
`


class ServicesPage extends React.Component {

    render() {
        return (
            <InternalLayout>
                <Seo title="A list of Services offered by Element Physiotherapy" />

                <Container>
                    <h1>Services</h1>
                    <h2>Physiotherapy Services</h2>
                    <Services>
                        {this.props.data.physioServices.edges.map(service => {
                            // filter out drafts in production
                            return (
                                <ServiceCard
                                    key={service.node.fields.id}
                                    service={service.node}
                                    // handler={() => this.handleClick(service)}
                                    // onClick={e => this.setState({activeIndex: i)}}
                                    // className= {`${i == this.state.activeIndex ? "active" : "inactive"}
                                    // state={this.state.active}
                                    // className={this.state.active ? 'active': 'disabled'}
                                    // onClick={this.addActiveClass()}
                                />
                            );
                        })}
                    </Services>
                    <h2>Supervised Exercise Services</h2>
                    <Services>
                        {this.props.data.supervisedServices.edges.map(service => {
                            // filter out drafts in production
                            return (
                                <ServiceCard
                                    key={service.node.fields.id}
                                    service={service.node}
                                />
                            );
                        })}
                    </Services>
                </Container>
            </InternalLayout>
        )
    }
}

export default ServicesPage

export const pageQuery = graphql`
    query {
         physioServices: allMarkdownRemark(
            sort: {fields: frontmatter___tags, order: ASC}
            filter: {frontmatter: {tags: {in: "physiotherapy"}}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  layout
                  author
                  tags
                  serviceImage {
                    id
                    childImageSharp {
                      gatsbyImageData(quality: 60, formats: [AUTO, WEBP], placeholder: BLURRED)
                    }
                    internal {
                      content
                      description
                      ignoreType
                      mediaType
                    }
                  }
                }
                fields {
                  slug
                }
                id
                timeToRead
                html
              }
            }
          }
          supervisedServices: allMarkdownRemark(
            sort: {fields: frontmatter___tags, order: ASC}
            filter: {frontmatter: {tags: {in: "supervised-exercise"}}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  layout
                  author
                  tags
                  serviceImage {
                    id
                    childImageSharp {
                       gatsbyImageData(quality: 60, formats: [AUTO, WEBP], placeholder: BLURRED)
                    }
                    internal {
                      content
                      description
                      ignoreType
                      mediaType
                    }
                  }
                }
                fields {
                  slug
                }
                id
                timeToRead
                html
              }
            }
          }
    }
`;
